import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Icon } from "../Icons";
import * as styles from "./AnchorNav.module.scss";

const AnchorNav = ({ sections }) => {
	const [isMenuOpened, setIsMenuOpened] = useState(false);
	const handleToggleMenu = () => {
		setIsMenuOpened(!isMenuOpened);
	};
	const scrollTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};
	return (
		<>
			<div className={styles.navOverlay} data-nav-opened={isMenuOpened} />
			<div className={styles.nav} data-nav-opened={isMenuOpened}>
				<div className={styles.navTop}>
					<strong>On this page</strong>
					<button
						type="button"
						className={styles.navGrip}
						title={isMenuOpened ? "Close Page Navigation" : "Open Page Navigation"}
						onClick={handleToggleMenu}
					>
						<Icon name="menu" className={cx(styles.icon, styles.iconMenuSm)} />
						<Icon name="menu" className={cx(styles.icon, styles.iconListSm)} />
						<Icon name="close--n" className={cx(styles.icon, styles.iconCloseSm)} />
					</button>
				</div>
				<div className={styles.navBottom}>
					<ul className={styles.navList}>
						<li className={styles.navItem}>
							<button type="button" className={styles.navLink} onClick={scrollTop}>
								To top
							</button>
						</li>
						{sections.map(item => (
							<li key={item.titleId} className={styles.navItem}>
								<a href={`#${item.titleId}`} className={styles.navLink}>
									{item.displayTitle}
								</a>
							</li>
						))}
					</ul>
				</div>
			</div>
		</>
	);
};

AnchorNav.propTypes = {
	sections: PropTypes.arrayOf(
		PropTypes.shape({
			titleId: PropTypes.string,
			displayTitle: PropTypes.string,
		})
	),
};
AnchorNav.defaultProps = {
	sections: [],
};

export default AnchorNav;
export { AnchorNav };
